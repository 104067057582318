import React from "react";
import EvaluationLine from "./evaluation-line";

const EvaluationContainer = (props) => {
  const { Step, ArrayQuestions } = props;
  const newEvaluation = ArrayQuestions[Step].subjectQuestions
    .sort((a, b) => a.questionTypeId - b.questionTypeId)
    .toReversed();


  return (
    <>
      <ul>
        {newEvaluation.map((item, index) => {
          return <EvaluationLine Item={item} key={item.questionId} />;
        })}
      </ul>
    </>
  );
};

export default EvaluationContainer;
