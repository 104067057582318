
const HomeTop = (props) => {
    const { Title , Discription } = props
    return (
        <div className='home__content__top'>
            <h6>{Title}</h6>
            <p>{Discription}</p>
        </div>
    )
    
}

export default HomeTop