
const DetailsTop = (props) => {
    const { Title, Code,CourseName } = props;

    return (
        <div className='details__content__top'>
            <div className={`details__content__top__subject`}>
                <img src="/assets/icons/icon-chemistry.svg" alt="Logo" />
                <p>
                    <span className="title__subject">{Title}</span>
                    <span>-</span>
                    <span>{Code}</span>
                </p>
            </div>
            <div className="details__content__top__course-name">
                <p><span>اسم الدورة :</span><span>{CourseName}</span></p>
            </div>
        </div>
    )

}

export default DetailsTop