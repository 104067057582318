import Cookies from "js-cookie";

const ResultEnd = (props) => {
  const LogOut = () => {
    localStorage.removeItem("user-study-study");
    Cookies.remove("user-study-name");
    Cookies.remove("user-study-token");
    Cookies.remove("classroom-study");

    Cookies.remove("testid-study");
    Cookies.remove("testSubjectName-study");
    Cookies.remove("testSubjectCode-study");
    Cookies.remove("testEndTime-study");
    Cookies.remove("subjectName-study");

    localStorage.removeItem("fullName-study");

    window.location.href = "/signin";
  };
  return (
    <div className="system__content__end">
      <button className="btn btn-logout" type="button" onClick={LogOut}>
        تسجيل الخروج
      </button>
    </div>
  );
};

export default ResultEnd;
