/*
export const requestHeaders: HeadersInit = new Headers();
requestHeaders.append("Accept", "application/json");
requestHeaders.append("Content-Type", "application/json");
*/
export const BACKEND_URL = "https://www.etsapp.somee.com/api/";
export const requestHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const HeadersFile = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
};
export const HeadersDownloadFile = {
  Accept: "application/json",
  "Content-Type":
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ",
  "Access-Control-Allow-Origin": "*",
};
