/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import { useLocation } from 'react-router-dom';
import { useState } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Cookies from "js-cookie";


const CountdownCircle = () => {
  let location = useLocation();
  const testEndTime = Cookies.get("testEndTime-study");

  const StartDay = new Date();
  const EndDay = testEndTime ? new Date(testEndTime) : new Date();
  const [relTimeSeconds, setRelTimeSeconds] = useState(1);

  const nowMinutes = StartDay.getHours() * 60 + StartDay.getMinutes();
  const EndMinutes = EndDay.getMinutes() * 60 + EndDay.getSeconds();
  const secondsTime = Math.floor(nowMinutes * 60) - Math.floor(EndMinutes * 60);

  return (
    <div className='system__content__top__countdown-circle border-right-top'>
      {location.pathname == "/end-questions" ?
        <>
        </> : <>
          <div className="d-flex gap-2">

            <UrgeWithPleasureComponent Seconds={secondsTime} relTimeSeconds={relTimeSeconds} setRelTimeSeconds={setRelTimeSeconds} />
          </div>
        </>}
    </div>
  )
}

export default CountdownCircle;


const UrgeWithPleasureComponent = (props) => {
  const { Seconds, relTimeSeconds, setRelTimeSeconds } = props;
  return (<>
      <CountdownCircleTimer
        isPlaying
        duration={Seconds}
        colors={['#68c272', '#A30000', '#A30000']}
        colorsTime={[300, 120, 0]}
        strokeWidth={5}
        size={50}
      >
        {({ remainingTime }) => {
          setRelTimeSeconds(remainingTime);
          return null; // Replace with the JSX element you want to render
        }}
      </CountdownCircleTimer>
      <ShowTime TimeSeconds={relTimeSeconds} />
  </>
  );
}


const ShowTime = (props) => {
  const { TimeSeconds } = props;
  const seconds = TimeSeconds % 60 === 0 ? 0 : TimeSeconds % 60 > 9 ? TimeSeconds % 60 : "0" + TimeSeconds % 60;
  {/* Get minutes By Seconds */ }
  const minutes = Math.floor(TimeSeconds / 60) === 0 ? 0 : Math.floor(TimeSeconds / 60) > 9 ? Math.floor(TimeSeconds / 60) : "0" + (Math.floor(TimeSeconds / 60) > 60 ? "00" : Math.floor(TimeSeconds / 60));
  const newMinutes = Number(minutes) % 60;
  const hours = Math.floor(TimeSeconds / 3600) === 0 ? 0 : Math.floor(TimeSeconds / 3600) > 9 ? Math.floor(TimeSeconds / 3600) : "0" + Math.floor(TimeSeconds / 3600);
  const time = `${hours === 0 ? "00" : hours}:${newMinutes === 0 ? "00" : newMinutes < 10 ? "0" + newMinutes : newMinutes}:${seconds === 0 ? "00" : seconds}`;


  return (
    
    <div className="countdown-circle-text">
    <p className="text">متبقي</p>
    <p className="time">
      <span className="data" style={{
        fontSize: "18px"
      }}>{time}</span>
      <span className="menute">دقيقة</span>
    </p>
  </div> 
  )
}
