/* eslint-disable eqeqeq */
import { Formik } from "formik";
//import { useRouter } from "next/router";
import { useState } from "react";
import { InputOuter, schema } from "./form-data";
import { Headers } from "../../../api/actions";
import { Api } from "../../../api/index";
import Cookies from "js-cookie";
import axios from "axios";
import axiosInstance from "../../../api/axios_instance";

export function LoginForm() {
  const [massegeerror, setMassegeError] = useState("");
  const state = {
    idnumber: "",
  };

  const form = (props) => {
    return (
      <form onSubmit={props.handleSubmit}>
        <InputOuter
          Error={props.errors.idnumber}
          Value={props.values.idnumber}
          Type={"number"}
          Name={"idnumber"}
          Placeholder={"رقم الهوية"}
        />
        {massegeerror && (
          <div className="errorfiled">
            <p>{massegeerror}</p>
          </div>
        )}

        <button type="submit" className={"btn"}>
          تسجيل الدخول
        </button>
      </form>
    );
  };

  const SignIn = (values) => {
    OnSubmit(values.idnumber, setMassegeError);
  };
  return (
    <div className="login__form">
      <Formik
        initialValues={state}
        onSubmit={SignIn}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />
    </div>
  );
}

const OnSubmit = async (idnumber, setMassegeError) => {
  var data = new FormData();
  data.append("militaryNumber", idnumber);
  const options = {
    method: "post",
    url: `${Api}ApplicationDbUser/StudentLogin`,
    headers: Headers,
    data,
  };
  await axios(options)
    .then(function (response) {
      if (response.data.responseStatus.errors.length > 0) {
        setMassegeError(
          response.data.responseStatus.errors
            .map((itm) => itm.errorMessage)
            .join("\n")
        );
      } else {
        Cookies.remove("user-study-token");
        Cookies.set("user-study-token", response.data.responseObject.token);
        localStorage.setItem(
          "user-study-study",
          response.data.responseObject.studentId
        );
        Cookies.remove("user-study-name");
        Cookies.set("user-study-name", response.data.responseObject.fullName);

        localStorage.removeItem("fullName-study");
        localStorage.setItem(
          "fullName-study",
          response.data.responseObject.fullName
        );

        window.location.reload();
      }
      console.log(response);
    })
    .catch(function (error) {});
};
