import React from 'react'

const LoginTitle = (props) => {
    return (
        <div className='login__title'>
            <p>
                اهلا ،
            </p>
            <p>
                قم بتسجيل الدخول !
            </p>
        </div>
    )
}

export default LoginTitle