

const ProgressBar = (props) => {
    const { Quetsions, End } = props;
    const number = End+1;
    return (
        <div className="questions__content__top__progress-bar">
            <p className="text">الاسئلة</p>
            <div className="questions__content__top__progress-bar__progress">
                <div className="questions__content__top__progress-bar__progress__text">
                    <span className="number">{number} / {Quetsions}</span>
                    {End === Quetsions ?
                        <span className="text"> اكتملت</span>
                        : null}
                </div>
                <div className="questions__content__top__progress-bar__progress__bar">
                    <div className="questions__content__top__progress-bar__progress__bar__line" style={{ width: `${(number / Quetsions) * 100}%` }}></div>
                </div>
            </div>
        </div>
    )
}

export default ProgressBar