import Navbar from "./navbar";

export default function Layout({ children }) {
  return (
    <main className="main">
      <Navbar />
      {children}
    </main>
  );
}
