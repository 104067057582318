import axios from "axios";
import Cookies from "js-cookie";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("user-study-token");
    console.log("test");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user-study-study");
      Cookies.remove("user-study-name");
      Cookies.remove("user-study-token");
      Cookies.remove("classroom-study");

      Cookies.remove("testid-study");
      Cookies.remove("testSubjectName-study");
      Cookies.remove("testSubjectCode-study");
      Cookies.remove("testEndTime-study");
      Cookies.remove("subjectName-study");

      localStorage.removeItem("fullName-study");
      window.location.reload();
    }
  }
);

export default axiosInstance;
