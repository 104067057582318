/* eslint-disable no-lone-blocks */
'use client';
import { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const HomeEnd = (props) => {
    const { Item } = props;
    const StartDay = new Date(),
        EndDay = new Date(Item.testEndEnd);
   const [relTimeSeconds, setRelTimeSeconds] = useState(1);

    const nowMinutes = StartDay.getHours() * 60 + StartDay.getMinutes(),
        EndMinutes = EndDay.getMinutes() * 60 + EndDay.getSeconds();
    const secondsTime = Math.floor(nowMinutes * 60) - Math.floor(EndMinutes * 60);


    return (<>
    {relTimeSeconds === 0 ?
     <div className='home__content__discription__end'>
            <p className="title">الاختبار الأن</p>
        </div>
         :
        <div className='home__content__discription__end'>
            <p className="title">يبدأ الاختبار بعد</p>
            <UrgeWithPleasureComponent Seconds={secondsTime} relTimeSeconds={relTimeSeconds} setRelTimeSeconds={setRelTimeSeconds} />
        </div>
        }
    </>
    )
}

export default HomeEnd;


const UrgeWithPleasureComponent = (props) => {
    const { Seconds ,relTimeSeconds,setRelTimeSeconds } = props;
    return (<>
        <div className="countdown">
            <CountdownCircleTimer
                isPlaying
                duration={Seconds}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}
                strokeWidth={5}
                size={50}
            >
                {({ remainingTime }) => {
                    setRelTimeSeconds(remainingTime);
                    return null; // Replace with the JSX element you want to render
                }}
            </CountdownCircleTimer>
        </div>
        <ShowTime TimeSeconds={relTimeSeconds} />
    </>
    );
}


const ShowTime = (props) => {
    const { TimeSeconds } = props;
    const seconds = TimeSeconds % 60 === 0 ? 0 : TimeSeconds % 60 > 9 ? TimeSeconds % 60 : "0" + TimeSeconds % 60;
    {/* Get minutes By Seconds */}
    const minutes = Math.floor(TimeSeconds / 60) === 0 ? 0 : Math.floor(TimeSeconds / 60) > 9 ? Math.floor(TimeSeconds / 60) : "0" + (Math.floor(TimeSeconds / 60) > 60 ? "00" : Math.floor(TimeSeconds / 60));
    const newMinutes = Number(minutes) % 60;
    const hours = Math.floor(TimeSeconds / 3600) === 0 ? 0 : Math.floor(TimeSeconds / 3600) > 9 ? Math.floor(TimeSeconds / 3600) : "0" + Math.floor(TimeSeconds / 3600);
    const time = `${hours === 0 ? "00" : hours}:${newMinutes === 0 ? "00" : newMinutes < 10 ? "0" + newMinutes : newMinutes}:${seconds === 0 ? "00" : seconds}`;

    
    return (
        <p className="time">
            <span className="time__number">
                {time}
            </span>
            <span className="time__text">دقيقة</span>
        </p>
    )
}

