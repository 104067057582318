import React from "react";
import { Rating } from "react-simple-star-rating";
import axios from "axios";
import Cookies from "js-cookie";
import { requestHeaders, BACKEND_URL } from "../../../../utils/headers";
import axiosInstance from "../../../../api/axios_instance";

const RatingContener = (props) => {
  const { rating, setRating, Item } = props;

  const StudentId = localStorage.getItem("user-study-study");
  const handleRating = (e) => {
    const TestId = Cookies.get("testid-study");
    Item.value = e;
    setRating(e);
    SendData(StudentId, TestId, Item.questionId, e);
  };
  return (
    <div className="rating">
      <Rating
        onClick={handleRating}
        initialValue={rating}
        ratingValue={rating}
        size={30}
        rtl={true}
      />
    </div>
  );
};

export default RatingContener;
const SendData = async (StudentId, TestId, surveyQuestionId, studentAnswer) => {
  const options = {
    method: "post",
    url: `${BACKEND_URL}Survey/SaveSurveyAnswer`,
    headers: requestHeaders,
    data: [
      {
        studentId: StudentId,
        testId: TestId,
        surveyQuestionId: surveyQuestionId,
        studentAnswer: `${studentAnswer}`,
      },
    ],
  };
  await axiosInstance(options)
    .then(function (response) {})
    .catch(function (error) {});
};
