import Circular from "./circular-progress";

const ResultContent = (props) => {
    const { Title, Success ,EndData ,Number} = props;
    return (
        <div className={Success === true ? "result-content result-content-success " : 'result-content result-content-fail'}>
            <div className="result-content-card">
                <h6>{Title}</h6>
                <div className="result-content-card__content">
                    <Circular Number={Number}/>
                    <span className="degree">درجة</span>
                </div>
                <div className="result-content-card__end">
                    <span>{EndData}</span>
                </div>
            </div>
        </div>
    )
}

export default ResultContent;