//const matchingQuestions=[];

//const nonMatchingQuestions=[];
const Questions = [];
export const QuestionsCheck = [];

function removeDuplicates(Questions, setData) {
  const ids = Questions.map(({ id }) => id);
  const filtered = Questions.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  const sorted = filtered.sort((a, b) => a.order - b.order);
  console.log(sorted);

  setData(sorted);
}

export function AddQuestions(matchingQuestions, nonMatchingQuestions, setData) {
  if (matchingQuestions.length > 0) {
    for (let i = 0; i < matchingQuestions.length; i++) {
      Questions.push({
        id: matchingQuestions[i].questions[0].questionId,
        order: matchingQuestions[i].questionOrder,
        type: matchingQuestions[i].answers[0].answerTypeId,
        question: matchingQuestions[i].questions,
        answers: matchingQuestions[i].answers,
        isSelect: matchingQuestions[i].answers.some(
          (itm) => itm.selectedAnswer
        ),
        EndAnswer: matchingQuestions[i].answers.map((answer) => ({
          questionId: answer?.selectedAnswer,
          answerId: answer.answerId,
          value:
            matchingQuestions[i].questions.findIndex(
              (obj) => obj.questionId === answer?.selectedAnswer
            ) + 1,
        })),
      });
    }
  }

  if (nonMatchingQuestions.length > 0) {
    for (let i = 0; i < nonMatchingQuestions.length; i++) {
      Questions.push({
        id: nonMatchingQuestions[i].questionId,
        order: nonMatchingQuestions[i].questionOrder,
        type: nonMatchingQuestions[i].answerTypeId,
        question: [
          {
            questionId: nonMatchingQuestions[i].questionId,
            questionName: nonMatchingQuestions[i].questionName,
          },
        ],
        answers: nonMatchingQuestions[i].questionAnswers,
        isSelect: false,
        EndAnswer: [
          {
            questionId: nonMatchingQuestions[i].questionId,
            answerId: nonMatchingQuestions[i].isAnswerSelected
              ? nonMatchingQuestions[i].questionAnswers.find(
                  (itm) => itm.isSelectedByStudent
                )?.answerId
              : "",
          },
        ],
      });
    }
  }

  removeDuplicates(Questions, setData);
  /*
  matchingQuestions.forEach((question) => {
    Questions.push(question);
  });
  nonMatchingQuestions.forEach((question) => {
    Questions.push(question);
  });
  */
}
