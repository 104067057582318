
const Subject = (props) => {
    const { Title, Code } = props
    return (
        <div className='system__content__top__subject border-left-top'>
            <img src="/assets/icons/icon-chemistry.svg" alt="Logo" />
            <p>
                <span className="title__subject">{Title}</span>
                <span>-</span>
                <span>{Code}</span>
            </p>
        </div>
    )
}

export default Subject;