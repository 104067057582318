import React, { useState } from "react";
import EvaluationContainer from "./container";
import EvaluationButtons from "./buttons";
import IconVector from "../../../components/image/icon-vector.png";

const EvaluationContent = (props) => {
  const { EvaluationData } = props;
  const [step, setStep] = useState(0);
  const [answerActive, setAnswerActive] = useState([]);
  return (
    <div className="questions__content">
      <div className="questions__content__evaluation-exam-top">
        <p>{EvaluationData[step].surveyTypeName}</p>
      </div>
      <QuestionsContent
        EvaluationData={EvaluationData}
        step={step}
        setStep={setStep}
        answerActive={answerActive}
        setAnswerActive={setAnswerActive}
      />
    </div>
  );
};

export default EvaluationContent;

const QuestionsContent = (props) => {
  const { EvaluationData, step, setStep } = props;
  return (
    <>
      <div className="questions__content__evaluation-exam">
        <EvaluationExamTop />
        <span className="line"></span>
        <EvaluationContainer Step={step} ArrayQuestions={EvaluationData} />
      </div>
      <EvaluationButtons step={step} setStep={setStep} ArrayQuestions={EvaluationData}/>
    </>
  );
};

const EvaluationExamTop = (props) => {
  const ArrayData = [
    {
      text: "غير راضي تماما",
      Icons: [IconVector],
    },
    {
      text: "غير راضي",
      Icons: [IconVector, IconVector],
    },
    {
      text: "محايد",
      Icons: [IconVector, IconVector, IconVector],
    },
    {
      text: "راضي",
      Icons: [IconVector, IconVector, IconVector, IconVector],
    },
    {
      text: "راضي تماما",
      Icons: [IconVector, IconVector, IconVector, IconVector, IconVector],
    },
  ];
  return (
    <div className="questions__content__evaluation-exam__top">
      <div className="left">
        <h3>التقييم</h3>
        <p>من فضلك قم بملئ استبانه التقييم التالية</p>
      </div>
      <div className="right">
        {ArrayData.map((item, index) => (
          <span className="data" key={index}>
            <span className="text">{item.text}</span>
            <span className="icon-rows">
              {item.Icons.map((icon, index) => (
                <span className="icon" key={index}>
                  <img src={icon} alt="icon-vector" />
                </span>
              ))}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};
