const Questions = [];

function removeDuplicates(Questions, setData) {

  const ids = Questions.map(({ id }) => id);
  const filtered = Questions.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  setData([...filtered]);
}

export function AddEvaluation(evaluation, setData) {
  for (let i = 0; i < evaluation.length; i++) {
    for (let j = 0; j < evaluation[i].subjectQuestions.length; j++) {
      if (evaluation[i].subjectQuestions[j].questionTypeId === 1) {
        Questions.push({
          id: evaluation[i].subjectQuestions[j].questionId,
          questionName: evaluation[i].subjectQuestions[j].questionName,
          type: evaluation[i].subjectQuestions[j].questionTypeId,
          surveyTypeId: evaluation[i].surveyTypeId,
          answer: '',
        });
      }

      if (evaluation[i].subjectQuestions[j].questionTypeId === 2) {
        Questions.push({
          id: evaluation[i].subjectQuestions[j].questionId,
          questionName: evaluation[i].subjectQuestions[j].questionName,
          type: evaluation[i].subjectQuestions[j].questionTypeId,
          surveyTypeId: evaluation[i].surveyTypeId,
          answer: '',
        });
      }

    }
  }

  removeDuplicates(Questions, setData);
}