/* eslint-disable eqeqeq */
import { useState } from "react";
import swal from "sweetalert";

const QuestionsReceipt = (props) => {
  const { Question, objectArrayAnswers } = props;
  const objectValueAll = [];

  for (let index = 0; index < Question.question.length; index++) {
    const arrayObject = {
      idQuestion: Question.question[index].questionId,
      textQuestion: Question.question[index].questionName,
      typeQuestion: "",
      idAnswer:
        Question.answers[index] === null ||
        Question.answers[index] === undefined
          ? ""
          : Question.answers[index].answerId,
      textAnswer:
        Question.answers[index] === null ||
        Question.answers[index] === undefined
          ? ""
          : Question.answers[index].answerName,
      typeAnswer: "",
      selectedAnswer:
        Question.answers[index] === null ||
        Question.answers[index] === undefined
          ? ""
          : Question.answers[index].selectedAnswer,
    };
    objectValueAll.push(arrayObject);
  }
  const ValdetionDuplicate = [];

  for (let index = 0; index < Question.question.length; index++) {
    objectArrayAnswers.push({
      questionId: "",
      answerId: "",
    });
  }
  return (
    <>
      {objectValueAll.map((item, index) => {
        return (
          <div
            className="questions__content__container__content__answers-three-all"
            key={index}
          >
            <div className="questions__content__container__content__answers-three">
              <div className="questions__content__container__content__answers-three__item">
                <span className="type">
                  <span className="data">{index + 1}</span>
                </span>
                <span className="text">{item.textQuestion}</span>
              </div>
            </div>
            <div className="questions__content__container__content__answers-three">
              <FormSelect
                item={item}
                index={index}
                objectValueAll={objectValueAll}
                ValdetionDuplicate={ValdetionDuplicate}
                Question={Question}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
export default QuestionsReceipt;

const FormSelect = (props) => {
  const { item, index, objectValueAll, ValdetionDuplicate, Question } = props;
  const [value, setValue] = useState(
    Question.EndAnswer[index].value == undefined
      ? 0
      : Question.EndAnswer[index].value
  );

  const handleChangeAnswer = (event, index) => {
    const value = event.target.value;

    Question.EndAnswer[index].questionId = objectValueAll[value - 1].idQuestion;
    Question.EndAnswer[index].answerId = objectValueAll[index].idAnswer;
    Question.EndAnswer[index].value = event.target.value;
    setValue(value);
    /*
    if (value == 0) {
      ValdetionDuplicate.splice(index, 1);
      setValue(value);
      return false;
    } else {
      if (ValdetionDuplicate.indexOf(value) === -1) {
        ValdetionDuplicate.splice(index, 1);
        ValdetionDuplicate[index] = value;
      } else {
        swal("تم أختيار هذه الاجابه من قبل لسؤال اخر", {
          icon: "info",
          timer: 1000,
          className: "swal-contact-us",
        });
      }
    }
      */
  };

  return (
    <>
      <div className="questions__content__container__content__answers-three__item text-center">
        <select
          value={
            Question.EndAnswer[index].value == undefined
              ? 0
              : Question.EndAnswer[index].value
          }
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => handleChangeAnswer(e, index)}
        >
          <option value={0}>رقم السؤال</option>
          {objectValueAll.map((item, endex) => {
            return (
              <option key={endex} value={endex + 1}>
                {endex + 1}
              </option>
            );
          })}
        </select>
        <span className="text">{item.textAnswer}</span>
      </div>
    </>
  );
};
