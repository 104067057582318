
const ResultTitle = () => {
  return (
    <div className='result-title'>
        <p>نتيجة الاختبار</p>
        <h6>النتيجة النهائية</h6>
    </div>
  )
}

export default ResultTitle;