
const DetailsEnd = () => {
    const NextPage = () => {
      window.location.href = `/questions`
    }

    return (
        <div className="details__content__end">
            <button className="btn btn-logout" type="button" onClick={NextPage}>
                بدء الاختبار
            </button>
        </div>
    )

}

export default DetailsEnd