
const ListQuestions = (props) => {
    const {Quetsions ,Step}= props;
    return (
        <div className='questions__content__container__list'>
            <h5>سؤال رقم {Step + 1}</h5>

            <ul className="questions__content__container__list__row">
                {Quetsions.map((item, index) => {
                    return (
                        <li key={index}
                            className={Step === index ? "questions__content__container__list__row__item active" :
                            Step > index  ? "questions__content__container__list__row__item end"
                                    : "questions__content__container__list__row__item"}>
                            <span>{index + 1}</span>
                        </li>
                    )
                })}

            </ul>
        </div>
    )
}

export default ListQuestions