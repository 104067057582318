import React from 'react'
import ResultTitle from './result-title'
import ResultContent from './result-container'

const ResultContainer = (props) => {
    const { Title ,EndData ,Success ,Number } = props
  return (
    
    <div className='system__content__result-container'>
        <ResultTitle/>
        <ResultContent Title={Title} Success={Success} EndData={EndData} Number={Number}/>
    </div>
  )
}

export default ResultContainer