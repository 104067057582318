import React, { useEffect, useState } from "react";
import QuestionMatching from "./question-matching";
import QuestionsMyChoice from "./questions-mychoice";
import QuestionsTrueAndFalse from "./questions-trueandfalse";
const QuestionsContainer = (props) => {
  const { Questions, Step, setStep } = props;
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [Step]);

  if (active === true) {
    return <div>loading...</div>;
  }
  return (
    <>
      {Questions[Step].type === 1 && (
        <QuestionsMyChoice
          Quetsions={Questions}
          Step={Step}
          setStep={setStep}
          setActive={setActive}
        />
      )}
      {Questions[Step].type === 2 && (
        <QuestionsTrueAndFalse
          Quetsions={Questions}
          Step={Step}
          setStep={setStep}
          setActive={setActive}
        />
      )}
      {Questions[Step].type === 3 && (
        <QuestionMatching
          Quetsions={Questions}
          Step={Step}
          setStep={setStep}
          setActive={setActive}
        />
      )}
    </>
  );
};

export default QuestionsContainer;
