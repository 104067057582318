import React, {  useState } from 'react';

export const Authcontext = React.createContext();

export function Contextprovider(props) {
   // const [language, setLanguage] = useState(JSON.parse(languagecklized));
   const [addressrequest,setAddressrequest]=useState("");
   const [centerrequest, setCenterrequest] = useState({ lat: 19.506077033688108 , lng:  48.907672703125016 });

    //cart
    const [cart, setCart] = useState([]);
    //Wishlist
    const [wishlist, setWishlist] = useState([]);


    const value = {
        centerrequest:centerrequest, 
        setCenterrequest :setCenterrequest,
        addressrequest:addressrequest,
        setAddressrequest:setAddressrequest,
        //cart
        cart: cart,
        setCart: setCart,
        //Wishlist
        wishlist: wishlist,
        setWishlist: setWishlist,
    }

    return (
        <Authcontext.Provider value={value}>
            {props.children}
        </Authcontext.Provider>
    )
}

