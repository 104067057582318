/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import RatingContener from "./rating";
import TrueAndFalse from "./true-and-false";

const EvaluationLine = (props) => {
  const { Item } = props;

  // const StudentId = localStorage.getItem("user-study-study");

  return (
    <>
      {Item.questionTypeId == 2 ? (
        <LineEvaluation Item={Item} />
      ) : (
        <LineTrueAndFalse Item={Item} />
      )}
    </>
  );
};

export default EvaluationLine;

const LineEvaluation = (props) => {
  const { Item } = props;
  const [rating, setRating] = useState(
    Item.value === undefined ? 0 : Item.value
  );
  const [text, setText] = useState("");

  const ArrayData = [
    {
      text: "غير راضي تماما",
    },
    {
      text: "غير راضي",
    },
    {
      text: "محايد",
    },
    {
      text: "راضي",
    },
    {
      text: "راضي تماما",
    },
  ];

  useEffect(() => {
    if (rating === 1) {
      setText(ArrayData[0].text);
    } else if (rating === 2) {
      setText(ArrayData[1].text);
    } else if (rating === 3) {
      setText(ArrayData[2].text);
    } else if (rating === 4) {
      setText(ArrayData[3].text);
    } else if (rating === 5) {
      setText(ArrayData[4].text);
    }
  }, [rating]);

  return (
    <li className="line-evaluation">
      <span className="title">{Item.questionName}</span>
      <span className="data">
        <RatingContener rating={rating} setRating={setRating} Item={Item} />
        <span className="text">{text}</span>
      </span>
    </li>
  );
};

const LineTrueAndFalse = (props) => {
  const { Item } = props;
  return (
    <li className="line-true-and-false">
      <span className="title">{Item.questionName}</span>
      <TrueAndFalse Item={Item} />
    </li>
  );
};
