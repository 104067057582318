import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Cookies from "js-cookie";

function AuthGuard({ children }) {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("user-study-token");
    if (token) {
      setToken(token);
    }
    setTimeout(() => setLoading(false), 50);
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!token && location.pathname !== "/signin") navigate("/signin");
    else if (location.pathname === "/signin" && token) navigate("/");
  }, [token, children, navigate, location.pathname, loading]);

  return <>{!loading && children}</>;
}

export default AuthGuard;
