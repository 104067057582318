
export const Headers= {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export const HeadersFile= {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
};
export const HeadersDownloadFile= {
    'Accept': 'application/json',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ',
    "Access-Control-Allow-Origin": "*",
};