import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router";
import ResultEnd from "../../system-sections/result/result-end";

export default function Navbar() {
  const [login, setLogin] = useState("");
  const ClassName =
    Cookies.get("class-room-name") === undefined
      ? ""
      : Cookies.get("class-room-name");
  const fullNameStudy = localStorage.getItem("fullName-study") ?? "";

  let location = useLocation(),
    textlocation = location.pathname,
    questions = textlocation.includes("questions");
  // useEffect(() => {
  //   // if (textlocation === "/") {
  //   //   Cookies.remove("user-study-study");
  //   //   Cookies.remove("testid-study");
  //   //   Cookies.remove("testSubjectName-study");
  //   //   Cookies.remove("class-room-name");
  //   // }
  // }, [textlocation]);
  useEffect(() => {
    setLogin(Cookies.get("user-study-token"));
  }, []);
  return (
    <header className="header">
      <div className="container">
        <nav>
          <div className="header__logo">
            <img src="/assets/images/logo.png" alt="Logo" />
          </div>
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <div className="header__login">
              {questions === true ? (
                <div className="data-login">
                  <ul>
                    <li>
                      <span className="data">اسم الطالب:</span>
                      <span className="value">{fullNameStudy}</span>
                    </li>
                    <li>
                      <span className="data">اسم الدورة :</span>
                      <span className="value">{ClassName}</span>
                    </li>
                  </ul>
                </div>
              ) : (
                login && <p>اهلا ، {fullNameStudy} !</p>
              )}
            </div>
            {login && <ResultEnd />}
          </div>
        </nav>
      </div>
    </header>
  );
}
