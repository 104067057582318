/* eslint-disable eqeqeq */
import React from "react";
import swal from "sweetalert";

const EvaluationButtons = (props) => {
  const { step, setStep, ArrayQuestions } = props;

  const subjectQuestions = ArrayQuestions[step].subjectQuestions;

  const NextStep = () => {
    for (let i = 0; i < subjectQuestions.length; i++) {
      if (subjectQuestions[i].value == undefined) {
        swal("يجب الاجابة على جميع الاسئلة", {
          icon: "warning",
          timer: 2000,
          className: "swal-contact-us",
        });
        return false;
      } else {
        if (step < ArrayQuestions.length - 1) {
          setStep(step + 1);
        } else {
          window.location.href = "/end-page";
        }
      }
    }
  };
  /*
   if (step < ArrayQuestions.length - 1) {
        setStep(step + 1);
      } else {
        /*
        if (newEvaluationDataVldetion[i].isSelect === false) {
          swal("يجب الاجابة على جميع الاسئلة", {
            icon: "warning",
            timer: 2000,
            className: "swal-contact-us",
          });
          return false;
        }
        window.location.href = "/end-page";
  */
  return (
    <div className="questions__content__evaluation-exam-button">
      {step === 0 ? null : (
        <button
          type="button"
          className="btn btn-back"
          onClick={() => {
            setStep(step - 1);
          }}
        >
          السابق
        </button>
      )}

      <button
        type="button"
        className="btn btn-next btn-send"
        onClick={NextStep}
      >
        <>{step < ArrayQuestions.length - 1 ? "التالي" : "عرض النتيجة"}</>
      </button>
    </div>
  );
};

export default EvaluationButtons;
