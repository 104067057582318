/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import axios from "axios";
import Loading from "../../components/loading/loading";
import { requestHeaders, BACKEND_URL } from "../../utils/headers";
import axiosInstance from "../../api/axios_instance";

export default function EndQuestions() {
  const [counter, setCounter] = useState(10);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const StudentId = localStorage.getItem("user-study-study");
    const TestId = Cookies.get("testid-study");
    GetData(StudentId, TestId, setData, setLoading);
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      window.location.href = "/evaluation";
    }
  }, [counter]);

  if (loading === false)
    return (
      <section className="questions">
        <Loading />
      </section>
    );

  return (
    <section className="questions">
      <div className="container">
        <div className="questions__content">
          <div className="questions__content__end-questions">
            <div className="end-questions-top">
              <div className="contenet">
                <h3>تهانينا</h3>
                <p className="start">
                  لقد اجبت على جميع الاسئله و اكملت الاختبار
                </p>
                <span className="line"></span>
                <p className="end">سيتم توجيهك تلقائيا لملئ استبانه التقييم</p>
              </div>
            </div>

            <Second second={counter} />
            <div className="end-questions-buttons">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  window.location.href = "/evaluation";
                }}
              >
                اضغط هنا اذا لم يتم توجيهك تلقائيا
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const Second = (props) => {
  const { second } = props;
  return (
    <div className="end-questions-bottom">
      <p>سيتم التوجيه تلقائيا خلال {second} ثانية</p>
    </div>
  );
};

const GetData = async (StudentId, TestId, setData, setLoading) => {
  var data = new FormData();
  data.append("studentId", StudentId);
  data.append("testId", TestId);
  const options = {
    method: "post",
    url: `${BACKEND_URL}Test/StartTest`,
    headers: requestHeaders,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.nonMatchingQuestions);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
