import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import 'react-circular-progressbar/dist/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';
import { Contextprovider } from './store/context.js';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";   
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-phone-input-2/lib/material.css'
import "react-datepicker/dist/react-datepicker.css";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Contextprovider>
    <App />
  </Contextprovider>
  </React.StrictMode>
);

reportWebVitals();
