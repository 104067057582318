import { CircularProgressbar } from 'react-circular-progressbar';

const Circular = (props) => {
    const { Number } = props;
    const percentage = Number;
    return (<div className='circular-progress'>
        <CircularProgressbar value={percentage} text={`${percentage}`} />
    </div>
    )
}

export default Circular;