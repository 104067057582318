import React from "react"
import Cookies from "js-cookie";
import HomeEnd from "../home-end"

const HomeDiscription = (props) => {
  const { Day, code, Subject, item } = props
  return (
    <div className='home__content__discription'>
      <HomeDay Day={Day} />
      <HomeCard code={code} Subject={Subject} item={item} />
      <HomeEnd Item={item} />
    </div>
  )
}

export default HomeDiscription;


const HomeDay = (props) => {
  const { Day } = props
  const NewDay = Day.split("T")[0].replace(/-/g,"/");
  return (
    <div className="home__content__discription__day">
      <span className="title">التاريخ</span>
      <span className="value">{NewDay}</span>
    </div>
  )
}
const HomeCard = (props) => {
  const {code, Subject ,item} = props
return (
  <button className='home__content__discription__card' type="button" onClick={() => {

      Cookies.remove("classroom-study");
      Cookies.set("classroom-study", item.classRoomName)

      Cookies.remove("testid-study");
      Cookies.set("testid-study", item.testId)

      Cookies.remove("testEndTime-study");
      Cookies.set("testEndTime-study", item.testEndEnd)

      Cookies.remove("testSubjectName-study");
      Cookies.set("testSubjectName-study", item.subjectName)

      Cookies.remove("testSubjectCode-study");
      Cookies.set("testSubjectCode-study", item.subjectCode)

      Cookies.remove("subjectName-study");
      Cookies.set("subjectName-study", item.subjectName)

      
      window.location.href = `/details/${item.testId}`

  }}>
      <div className="home__content__discription__card__content">
          <img src="/assets/icons/icon-chemistry.png" alt="Logo" />
          <span className="value">{Subject}</span>
      </div>
      <div className="home__content__discription__card__subject">
          <span className="value">{code}</span>
      </div>
  </button>
)
}
